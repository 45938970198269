<template>
    <div class="expert">
        <div v-if="expert === 'introduce'">
            <div ref="introduce" class="introduce">
                <div style="margin-bottom: 50px">
                    <div class="resume-title">
                        专家简述
                    </div>
                    <div v-if="showIntroduce === true">
                        <el-input type="textarea"
                                  resize="none"
                                  :rows="5"
                                  placeholder="专家的描述。"
                                  v-model="expertInfo.introduction"
                                  maxlength="1000"
                                  :show-word-limit="true"
                                  style="margin-bottom: 16px">
                        </el-input>
                    </div>
                    <div v-else style="display: flex; justify-content: space-between;font-size: 18px; margin-bottom: 30px">
                        <div>{{expertInfo.introduction}}</div>
                        <img v-if="user.expertAuthStatus !== 1" src="../../../../assets/imgs/circle/editorC.png" class="hand op updateTags" @click="editIntroduction"/>
                    </div>
                    <div class="resume-title" style="font-size: 16px">
                        专家标签：
                    </div>
                    <div v-if="showLabel === false">
                        <el-tag class="tags-style" v-for="(item, index) in expertInfo.label" :key="index" effect="plain" size="medium">{{ item }}</el-tag>
                    </div>
                    <div v-else>
                        <div class="hand op" style="color: #1C7EFF; font-size: 18px; width: 10%; margin-bottom: 16px" v-if="expertInfo.label.length < 5" @click="expertLabelAdd = true">
                            <i class="el-icon-circle-plus-outline" style="margin-right: 12px"></i>
                            <span>添加标签</span>
                        </div>
                        <el-tag closable class="tags-style" v-for="(item, index) in expertInfo.label" :key="index" effect="plain" size="medium" @close="labelClose(index)">{{ item }}</el-tag>
                        <el-input
                            class="input-new-tag"
                            v-if="expertLabelAdd === true"
                            v-model="expertLabelValue"
                            ref="saveLabelInput"
                            :maxlength="20"
                            size="small"
                            @blur="expertInputConfirm"
                        >
                        </el-input>
                    </div>
                    <div style="display: flex; justify-content: end" v-if="showIntroduce === true">
                        <div>
                            <el-button class="cancel-btn" @click="cancelExpert">取消</el-button>
                            <el-button class="save-btn" @click="saveExpert">保存</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="expert === 'synopsis'">
            <div style="margin-bottom: 50px">
                <div style="display: flex; justify-content: space-between">
                    <div class="resume-title">
                        工作业绩
                    </div>
                </div>
                <div v-if="sketchShow === false" class="hand op school-add" @click="user.expertAuthStatus === 1 ? utils.msg('审核中，无法编辑！') : sketchShow = true">
                    <i class="el-icon-circle-plus-outline" style="margin-right: 12px"></i>
                    <span>添加业绩</span>
                </div>
                <div v-if="sketchShow === true" style="margin-bottom: 40px">
                    <ExpertFrom type="sketch" @reMajor="getMajor" @pushOtherData="pushOtherData($event,expertInfo.sketch)"></ExpertFrom>
                </div>
                <div v-for="(item, index) in expertInfo.sketch" :key="new Date().getTime()">
                    <ExpertData :otherData="item" @reMajor="getMajor" @pushOtherData="pushOtherData" @upOtherData="upOtherData($event,index,expertInfo.sketch)" @delOtherData="delOtherData($event,index,expertInfo.sketch)"></ExpertData>
                </div>
            </div>
        </div>

        <div v-if="expert === 'thesis'">
            <div style="margin-bottom: 50px">
                <div style="display: flex; justify-content: space-between">
                    <div class="resume-title">
                        出版专著
                    </div>
                </div>
                <div v-if="monographShow === false" class="hand op school-add" @click="user.expertAuthStatus === 1 ? utils.msg('审核中，无法编辑！') : monographShow = true">
                    <i class="el-icon-circle-plus-outline" style="margin-right: 12px"></i>
                    <span>添加专著</span>
                </div>
                <div v-if="monographShow === true" style="margin-bottom: 40px">
                    <ExpertFrom type="monograph" @reMajor="getMajor" @pushOtherData="pushData($event,expertInfo.paper.monograph)"></ExpertFrom>
                </div>
                <div v-for="(item, index) in expertInfo.paper.monograph" :key="new Date().getTime()">
                    <ExpertData :otherData="item" @reMajor="getMajor" @pushOtherData="pushData" @upOtherData="upData($event,index,expertInfo.paper.monograph)" @delOtherData="delData($event,index,expertInfo.paper.monograph)"></ExpertData>
                </div>
            </div>
        </div>

        <div v-if="expert === 'thesis'">
            <div style="margin-bottom: 50px">
                <div style="display: flex; justify-content: space-between">
                    <div class="resume-title">
                        出版论文
                    </div>
                </div>
                <div v-if="paperShow === false" class="hand op school-add" @click="user.expertAuthStatus === 1 ? utils.msg('审核中，无法编辑！') : paperShow = true">
                    <i class="el-icon-circle-plus-outline" style="margin-right: 12px"></i>
                    <span>添加论文</span>
                </div>
                <div v-if="paperShow === true" style="margin-bottom: 40px">
                    <ExpertFrom type="paper" @reMajor="getMajor" @pushOtherData="pushData($event,expertInfo.paper.paper)"></ExpertFrom>
                </div>
                <div v-for="(item, index) in expertInfo.paper.paper" :key="new Date().getTime()">
                    <ExpertData :otherData="item" @reMajor="getMajor" @pushOtherData="pushData" @upOtherData="upData($event,index,expertInfo.paper.paper)" @delOtherData="delData($event,index,expertInfo.paper.paper)"></ExpertData>
                </div>
            </div>
        </div>

        <div v-if="expert === 'socialTitle'">
            <div style="margin-bottom: 50px">
                <div style="display: flex; justify-content: space-between">
                    <div class="resume-title">
                        社会头衔
                    </div>
                </div>
                <div v-if="rankShow === false" class="hand op school-add" @click="user.expertAuthStatus === 1 ? utils.msg('审核中，无法编辑！') : rankShow = true">
                    <i class="el-icon-circle-plus-outline" style="margin-right: 12px"></i>
                    <span>添加头衔</span>
                </div>
                <div v-if="rankShow === true" style="margin-bottom: 40px">
                    <ExpertFrom type="rank" @reMajor="getMajor" @pushOtherData="pushOtherData($event,expertInfo.rank)"></ExpertFrom>
                </div>
                <div v-for="(item, index) in expertInfo.rank" :key="new Date().getTime()">
                    <ExpertData :otherData="item" @reMajor="getMajor" @pushOtherData="pushOtherData" @upOtherData="upOtherData($event,index,expertInfo.rank)" @delOtherData="delOtherData($event,index,expertInfo.rank)"></ExpertData>
                </div>
            </div>
        </div>

        <div v-if="expert === 'topic'">
            <div style="margin-bottom: 50px">
                <div style="display: flex; justify-content: space-between">
                    <div class="resume-title">
                        咨询话题
                    </div>
                </div>
                <div v-if="topicShow === false" class="hand op school-add" @click="user.expertAuthStatus === 1 ? utils.msg('审核中，无法编辑！') : topicShow = true">
                    <i class="el-icon-circle-plus-outline" style="margin-right: 12px"></i>
                    <span>添加话题</span>
                </div>
                <div v-if="topicShow === true" style="margin-bottom: 40px">
                    <ExpertFrom type="topic" @reMajor="getExpertsTopic"></ExpertFrom>
                </div>
                <div v-for="(item, index) in topicInfo" :key="new Date().getTime()">
                    <ExpertData :otherData="item" @reMajor="getExpertsTopic"></ExpertData>
                </div>
            </div>
        </div>

        <div v-if="expert === 'expertOther'">
            <div style="margin-bottom: 50px">
                <div style="display: flex; justify-content: space-between">
                    <div class="resume-title">
                        参与制定本专业法律法规、标准规范情况
                    </div>
                </div>
                <div v-if="otherShow === false" class="hand op school-add" @click="user.expertAuthStatus === 1 ? utils.msg('审核中，无法编辑！') : otherShow = true">
                    <i class="el-icon-circle-plus-outline" style="margin-right: 12px"></i>
                    <span>添加其他</span>
                </div>
                <div v-if="otherShow === true" style="margin-bottom: 40px">
                    <ExpertFrom type="other" @reMajor="getMajor" @pushOtherData="pushOtherData($event,expertInfo.other)"></ExpertFrom>
                </div>
                <div v-for="(item, index) in expertInfo.other" :key="new Date().getTime()">
                    <ExpertData :otherData="item" @reMajor="getMajor" @pushOtherData="pushOtherData" @upOtherData="upOtherData($event,index,expertInfo.other)" @delOtherData="delOtherData($event,index,expertInfo.other)"></ExpertData>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ExpertFrom from "./ExpertFrom";
import ExpertData from "./ExpertData";
export default {
    name: "Expert",
    components: {ExpertData, ExpertFrom},
    data() {
        return {
            expertInfo: {
                paper: {}
            },

            topicInfo: [],

            sketchShow: false,

            monographShow: false,

            paperShow: false,

            rankShow: false,

            topicShow: false,

            otherShow: false,

            expertLabelValue: '',
            showLabel: false,
            showIntroduce: false,
            expertLabelAdd: false,
            expertLabels: [],

            topicList: []   // 话题
        }
    },

    computed: {
        user() {
            return this.$store.state.user
        }
    },

    props: {
      expert: {
        type: String,
        default () {
          return 'introduce'
        }
      }
    },

    mounted() {
        this.getMajor();
        this.getExpertsTopic()
    },

    methods: {
        // 获取专家话题
        getExpertsTopic() {
            this.topicShow = false;
            this.newApi.getExpertTopic({uuid: this.user.uuid}).then(res => {
                this.topicInfo = res.data;
                this.$emit('changeTopic', res.data)
            })
        },

        // 编辑专家简介
        editIntroduction() {
            this.showIntroduce = true;
            this.showLabel = true
        },
        // 删除标签
        tagsClose(e) {
            this.userLabel.splice(e, 1)
        },

        // 新增标签
        handleInputConfirm() {
            let inputValue = this.myLabelValue;
            if (inputValue) {
                this.userLabel.push(inputValue);
            }
            this.myLabelValue = '';
            this.myLabelAdd = false
        },


        // 取消专家简介
        cancelExpert() {
            this.getMajor();
            this.showIntroduce = false;
            this.showLabel = false;
        },
        // 删除专家标签
        labelClose(e) {
            this.expertInfo.label.splice(e, 1)
        },

        saveExpert() {
            if (this.expertInfo.introduction) {
              this.showLabel=false;
              this.showIntroduce = false;
              this.newApi.setExpertInfo({type: 'label', info:JSON.stringify(this.expertInfo.label)}).then(res => {
                if (res.isSuccess === 1) {
                  this.newApi.setExpertIntroduction({content: this.expertInfo.introduction}).then(res => {
                    if (res.isSuccess === 1) {
                      this.getMajor()
                    }
                  })
                }
              })
            } else {
              this.utils.msg('请填写专家简介！')
            }

        },

        // 新增专家标签
        expertInputConfirm() {
            let inputValue = this.expertLabelValue;
            if (inputValue) {
                this.expertInfo.label.push(inputValue);
            }
            this.expertLabelValue = '';
            this.expertLabelAdd= false
        },

        // 编辑其他认证信息
        upData(item, index, data) {
            data.splice(index, 1, item.info)
            let params = {};
            params.type = item.type;
            params.info = JSON.stringify(data)
            this.newApi.setExpertPaper(params).then(res => {
                if (res.isSuccess === 1) {
                    this.getMajor(item.type)
                }
            })
        },

        // 删除其他认证信息
        delData(type, index, data) {
            data.splice(index, 1)
            let params = {};
            params.type = type;
            params.info = JSON.stringify(data)
            this.newApi.setExpertPaper(params).then(res => {
                if (res.isSuccess === 1) {
                    this.getMajor()
                }
            })
        },

        // 添加其他认证信息
        pushData(item, data) {
            let params = {};
            data.push(item.info);
            params.type = item.type;
            params.info = JSON.stringify(data)
            this.newApi.setExpertPaper(params).then(res => {
                if (res.isSuccess === 1) {
                    this.getMajor(item.type)
                }
            })
        },

        // 编辑其他认证信息
        upOtherData(item, index, data) {
            data.splice(index, 1, item.info)
            let params = {};
            params.type = item.type;
            params.info = JSON.stringify(data)
            this.newApi.setExpertInfo(params).then(res => {
                if (res.isSuccess === 1) {
                    this.getMajor(item.type)
                }
            })
        },

        // 删除其他认证信息
        delOtherData(type, index, data) {
            data.splice(index, 1)
            let params = {};
            params.type = type;
            params.info = JSON.stringify(data)
            this.newApi.setExpertInfo(params).then(res => {
                if (res.isSuccess === 1) {
                    this.getMajor()
                }
            })
        },

        // 添加其他认证信息
        pushOtherData(item, data) {
            let params = {};
            data.push(item.info);
            params.type = item.type;
            params.info = JSON.stringify(data)
            this.newApi.setExpertInfo(params).then(res => {
                if (res.isSuccess === 1) {
                    this.getMajor(item.type)
                }
            })
        },

        // 获取专家认证信息
        getMajor(e) {
          var that = this;
          if (e === 'sketch') {
            that.sketchShow = false;
          }
          if (e === 'monograph') {
            that.monographShow = false;
          }
          if (e === 'paper') {
            that.paperShow = false;
          }
          if (e === 'rank') {
            that.rankShow = false;
          }
          if (e === 'other') {
            that.otherShow = false;
          }
          if (e === 'topic') {
            that.topicShow = false;
          }
          that.newApi.getUserExpertAuthTemp().then(res => {
              that.expertInfo = res.data;
              that.$emit('changeExpert', res.data)
          })
        }
    },
}
</script>

<style scoped>
.tags-style {
    border-radius: 20px;
    margin-right: 18px;
    font-size: 14px;
    color: #2970FF;
    vertical-align: middle;
}

.introduce {
  font-size: 18px;
  padding: 20px;
  box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
  border-radius: 5px 5px 5px 5px;
  border: 1px solid #BDBFC1;
}

.updateTags {
    width: 28px;
    height: 28px;
    margin-left: 30px;
    vertical-align: middle;
}

.input-new-tag {
    width: 160px;
    vertical-align: bottom;
    display: inline-block;
}

.resume-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 12px;
}

.school-add {
    color: #1C7EFF;
    font-size: 18px;
    width: 16%;
    margin-bottom: 20px;
}

.save-btn {
  width: 68px;
  height: 38px;
  color: #fff;
  background-color: #1C7EFF;
  border-radius: 5px;
}


/*公共*/

.hand{
    cursor:pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.op:active{
    opacity:0.6;
    -webkit-transition:all .1s;transition:all .1s;opacity:.6;
}

.op{
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all .5s;
    transition: all .5s;
}
</style>
