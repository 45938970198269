<template>
    <div class="other-from">
        <el-form v-if="type === 'sketch'" :model="otherInfo" :rules="sketchRules" :ref="type" class="demo-ruleForm">
            <el-form-item prop="name" style="margin-bottom: 20px">
                <el-input type="textarea"
                          resize="none"
                          :rows="2"
                          maxlength="50"
                          :show-word-limit="true"
                          placeholder="项目名称"
                          v-model="otherInfo.name">
                </el-input>
            </el-form-item>

            <el-form-item prop="content" style="margin-bottom: 20px">
                <el-input type="textarea"
                          resize="none"
                          :rows="4"
                          placeholder="内容"
                          v-model="otherInfo.content"
                          maxlength="500"
                          :show-word-limit="true">
                </el-input>
            </el-form-item>
            <div style="display: flex; margin: 10px 0">
                <div style="width: 10%; font-size: 18px">
                    项目周期
                </div>
                <div style="display: flex">
                    <el-form-item prop="time">
                        <div class="block">
                            <el-date-picker
                                size="small"
                                v-model="otherInfo.time"
                                type="daterange"
                                value-format="yyyy-MM-dd"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :unlink-panels="true">
                            </el-date-picker>
                        </div>
                    </el-form-item>
                </div>
            </div>
            <el-divider></el-divider>
            <div style="display: flex; justify-content: space-between">
                <el-checkbox v-model="otherInfo.isOvert" true-label="1" false-label="0">对外公开</el-checkbox>
                <div>
                    <el-button class="cancel-btn" @click="cancelInfo">取消</el-button>
                    <el-button class="save-btn" @click="saveInfo" v-if="!otherData.type">保存</el-button>
                    <el-button class="save-btn" @click="upInfo" v-else>保存</el-button>
                </div>
            </div>
        </el-form>

        <el-form v-if="type === 'monograph'" :model="otherInfo" :rules="monographRules" :ref="type" class="demo-ruleForm">
            <el-form-item prop="monographName" style="margin-bottom: 20px">
                <el-input type="textarea"
                          resize="none"
                          :rows="2"
                          placeholder="专著名称"
                          maxlength="50"
                          :show-word-limit="true"
                          v-model="otherInfo.monographName">
                </el-input>
            </el-form-item>
            <el-form-item prop="press" style="margin-bottom: 20px">
                <el-input type="textarea"
                          resize="none"
                          :rows="4"
                          placeholder="出版社信息"
                          v-model="otherInfo.press"
                          maxlength="500"
                          :show-word-limit="true">
                </el-input>
            </el-form-item>
            <el-form-item prop="explain" style="margin-bottom: 20px">
                <el-input type="textarea"
                          resize="none"
                          :rows="6"
                          placeholder="工作内容"
                          v-model="otherInfo.explain"
                          maxlength="500"
                          :show-word-limit="true">
                </el-input>
            </el-form-item>
            <div style="display: flex; margin: 10px 0">
                <div style="width: 10%; font-size: 18px">
                    出版日期
                </div>
                <div style="display: flex">
                    <el-form-item prop="time">
                        <div class="block">
                            <el-date-picker
                                size="small"
                                v-model="otherInfo.time"
                                type="date"
                                value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </div>
                    </el-form-item>
                </div>
            </div>
            <el-divider></el-divider>
            <div style="display: flex; justify-content: space-between">
                <el-checkbox v-model="otherInfo.isOvert" true-label="1" false-label="0">对外公开</el-checkbox>
                <div>
                    <el-button class="cancel-btn" @click="cancelInfo">取消</el-button>
                    <el-button class="save-btn" @click="saveInfo" v-if="!otherData.type">保存</el-button>
                    <el-button class="save-btn" @click="upInfo" v-else>保存</el-button>
                </div>
            </div>
        </el-form>

        <el-form v-if="type === 'paper'" :model="otherInfo" :rules="paperRules" :ref="type" class="demo-ruleForm">
            <el-form-item prop="paperName" style="margin-bottom: 20px">
                <el-input type="textarea"
                          resize="none"
                          :rows="2"
                          placeholder="论文标题"
                          maxlength="50"
                          :show-word-limit="true"
                          v-model="otherInfo.paperName">
                </el-input>
            </el-form-item>
            <el-form-item prop="paperNo" style="margin-bottom: 20px">
                <el-input type="textarea"
                          resize="none"
                          :rows="2"
                          placeholder="发表刊物及刊号"
                          v-model="otherInfo.paperNo"
                          maxlength="50"
                          :show-word-limit="true">
                </el-input>
            </el-form-item>
            <div style="display: flex; margin: 10px 0">
                <div style="width: 10%; font-size: 18px">
                    发表日期
                </div>
                <div style="display: flex">
                    <el-form-item prop="time">
                        <div class="block">
                            <el-date-picker
                                size="small"
                                v-model="otherInfo.time"
                                type="date"
                                value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </div>
                    </el-form-item>
                </div>
            </div>
            <el-divider></el-divider>
            <div style="display: flex; justify-content: space-between">
                <el-checkbox v-model="otherInfo.isOvert" true-label="1" false-label="0">对外公开</el-checkbox>
                <div>
                    <el-button class="cancel-btn" @click="cancelInfo">取消</el-button>
                    <el-button class="save-btn" @click="saveInfo" v-if="!otherData.type">保存</el-button>
                    <el-button class="save-btn" @click="upInfo" v-else>保存</el-button>
                </div>
            </div>
        </el-form>

        <el-form v-if="type === 'rank'" :model="otherInfo" :rules="rankRules" :ref="type" class="demo-ruleForm">
            <el-form-item prop="name" style="margin-bottom: 20px">
                <el-input type="textarea"
                          resize="none"
                          :rows="2"
                          placeholder="头衔名称"
                          maxlength="50"
                          :show-word-limit="true"
                          v-model="otherInfo.name">
                </el-input>
            </el-form-item>
            <div>
                <div style="font-size: 18px; margin-bottom: 18px">头衔证书（不对外展示）</div>
                <div style="width: 528px; height: 338px; border-radius: 5px;margin-right: 20px">
                    <el-upload :show-file-list="false" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" drag multiple :http-request="uploadFrontImg">
                        <img v-if="cartImg" width="100%" height="338px" :src="cartImg"/>
                        <i class="el-icon-plus"></i>
                        <div style="font-size: 20px">点击或拖动上传图片（10M以内）</div>
                        <div style="color: #999999; font-size: 18px">仅限jpg、png、bmp等图片格式</div>
                    </el-upload>
                </div>
            </div>
            <el-divider></el-divider>
            <div style="display: flex; justify-content: space-between">
                <el-checkbox v-model="otherInfo.isOvert" true-label="1" false-label="0">对外公开</el-checkbox>
                <div>
                    <el-button class="cancel-btn" @click="cancelInfo">取消</el-button>
                    <el-button class="save-btn" @click="saveInfo" v-if="!otherData.type">保存</el-button>
                    <el-button class="save-btn" @click="upInfo" v-else>保存</el-button>
                </div>
            </div>
        </el-form>

        <el-form v-if="type === 'topic'" :model="otherInfo" :rules="topicRules" :ref="type" class="demo-ruleForm">
            <el-form-item prop="field" style="margin-bottom: 30px">
                <span style="font-size: 16px; margin-right: 20px">专业领域：</span>
                <el-cascader v-model="otherInfo.field" :options="classOptions" @change="showResult" style="margin-bottom: 16px"></el-cascader>
            </el-form-item>
            <el-form-item prop="title" style="margin-bottom: 20px">
                <el-input type="textarea"
                          resize="none"
                          :rows="2"
                          maxlength="50"
                          :show-word-limit="true"
                          placeholder="专业领域话题"
                          v-model="otherInfo.title">
                </el-input>
            </el-form-item>
            <el-form-item prop="money" style="margin-bottom: 20px">
                <el-input type="textarea"
                          resize="none"
                          :rows="1"
                          placeholder="价格"
                          v-model="otherInfo.money">
                </el-input>
            </el-form-item>
            <el-form-item prop="detail" style="margin-bottom: 20px">
                <el-input type="textarea"
                          resize="none"
                          :rows="6"
                          placeholder="话题描述"
                          v-model="otherInfo.detail"
                          maxlength="500"
                          :show-word-limit="true">
                </el-input>
            </el-form-item>

            <el-form-item prop="status" style="margin-bottom: 20px">
                <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 15px;">
                    <div style="width: 100px;">是否上架：</div>
                    <div style="flex: 1">
                        <el-switch v-model="otherInfo.status" active-color="#13ce66" inactive-color="#ff4949" :active-value="2" :inactive-value="1"></el-switch>
                    </div>
                </div>
            </el-form-item>
            <el-divider></el-divider>
            <div style="display: flex; justify-content: end">
<!--                <el-checkbox v-model="otherInfo.isOvert" true-label="1" false-label="0">对外公开</el-checkbox>-->
                <div>
                    <el-button class="cancel-btn" @click="cancelInfo">取消</el-button>
                    <el-button class="save-btn" @click="saveInfo" v-if="!otherData.type">保存</el-button>
                    <el-button class="save-btn" @click="upInfo" v-else>保存</el-button>
                </div>
            </div>
        </el-form>

        <el-form v-if="type === 'other'" :model="otherInfo" :rules="otherCartRules" :ref="type" class="demo-ruleForm">
            <el-form-item prop="name" style="margin-bottom: 20px">
                <el-input type="textarea"
                          resize="none"
                          :rows="10"
                          placeholder="详细描述专家是否有在主要专业领域参与制定本专业法律法规、标准规范情况"
                          maxlength="1000"
                          :show-word-limit="true"
                          v-model="otherInfo.name">
                </el-input>
            </el-form-item>
            <el-divider></el-divider>
            <div style="display: flex; justify-content: space-between">
                <el-checkbox v-model="otherInfo.isOvert" true-label="1" false-label="0">对外公开</el-checkbox>
                <div>
                    <el-button class="cancel-btn" @click="cancelInfo">取消</el-button>
                    <el-button class="save-btn" @click="saveInfo" v-if="!otherData.type">保存</el-button>
                    <el-button class="save-btn" @click="upInfo" v-else>保存</el-button>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>

export default {
    name: "ExpertFrom",
    components: {},
    data() {
        return {
            otherInfo: this.otherData,
            cartImg: '',

            // 分类选择
            classOptions: [],
            selectClass: [],

            sketchRules: {
                name: [
                    {required: true, message: '请填写项目名称', trigger: 'blur'}
                ],
                content: [
                    {required: true, message: '请填写工作内容', trigger: 'blur'}
                ],
                time: [
                    {required: true, message: '请填写时间范围', trigger: 'blur'}
                ]
            },

            monographRules: {
                monographName: [
                    {required: true, message: '请填写专著名称', trigger: 'blur'}
                ],
                press: [
                    {required: true, message: '请填写出版社信息', trigger: 'blur'}
                ],
                explain: [
                    {required: true, message: '请填写工作内容', trigger: 'blur'}
                ],
                time: [
                    {required: true, message: '请填写出版时间', trigger: 'blur'}
                ]
            },

            paperRules: {
                paperName: [
                    {required: true, message: '请填写论文标题', trigger: 'blur'}
                ],
                paperNo: [
                    {required: true, message: '请填写发表刊物及刊号', trigger: 'blur'}
                ],
                time: [
                    {required: true, message: '请填写发表日期', trigger: 'blur'}
                ]
            },

            rankRules: {
                name: [
                    {required: true, message: '头衔名称', trigger: 'blur'}
                ],
            },

            NVQCartRules: {
                name: [
                    {required: true, message: '请填写职业资格名称', trigger: 'blur'}
                ],
            },

            otherCartRules: {
                name: [
                    {required: true, message: '请填写证书名称', trigger: 'blur'}
                ],
            },

            topicRules: {
                field: [
                    {required: true, message: '请填写专业领域', trigger: 'change'}
                ],
                title: [
                    {required: true, message: '请填写专业领域话题', trigger: 'blur'}
                ],
                money: [
                    {required: true, message: '请填写价格', trigger: 'blur'}
                ],
                detail: [
                    {required: true, message: '请填写描述信息', trigger: 'blur'}
                ],
                status: [
                    {required: true, message: '请选择是否上架', trigger: 'change'}
                ],
            },

        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    props: {
        otherData: {
            type: Object,
            default() {
                return {}
            }
        },

        type: {
            type: String,
            default() {
                return '';
            }
        }
    },
    created: function () {
      if (this.otherData.id) {
        this.otherInfo.field = this.otherData.typeArr
      }
    },
    mounted: function () {
        this.getClassInfo();
        this.cartImg = this.otherData.cartImg;
    },
    methods: {
        showResult(e) {
            if (e.length !== 0) {
                this.otherInfo.topicId = e[2]
            }
        },

        // 获取行业分类信息
        getClassInfo() {
            var that = this;
            that.newApi.getTypeList({}).then(res =>{
                that.classOptions = res.data
            })
        },

        cancelInfo() {
            this.$emit('reMajor', this.type)
        },

        // 保存表单
        saveInfo() {
            let that = this;
            that.$refs[that.type].validate(function (valid) {
                if (valid) {
                    if (that.type !== 'topic') {
                        let params = {};
                        params.type = that.type;
                        params.info = that.otherInfo;
                        params.info.type = that.type;
                        if (that.cartImg) {
                            params.info.cartImg = that.cartImg;
                        }
                        that.$emit('pushOtherData', params)
                    } else {
                        that.newApi.addExpertTopic({topics: JSON.stringify([that.otherInfo])}).then(res => {
                            if (res.isSuccess === 1) {
                                that.utils.sus(res.data);
                                that.$emit('reMajor', that.type)
                            }
                        })
                    }

                }
            })
        },

        // 编辑表单
        upInfo() {
            let that = this;
            that.$refs[that.type].validate(function (valid) {
                if (valid) {
                    if (that.type !== 'topic') {
                        let params = {};
                        params.type = that.type;
                        params.info = that.otherInfo;
                        params.info.type = that.type;
                        if (that.cartImg) {
                            params.info.cartImg = that.cartImg;
                        }
                        that.$emit('upOtherData', params)
                    } else {
                        let params = {};
                        params.id = that.otherData.id;
                        params.title = that.otherInfo.title;
                        params.money = that.otherInfo.money;
                        params.detail = that.otherInfo.detail;
                        params.topicId = that.otherInfo.field[2];
                        params.status = that.otherInfo.status;
                        params.uuid = that.otherData.uuid
                        that.newApi.editExpertTopic(params).then(res => {
                            if (res.isSuccess === 1) {
                                that.utils.sus(res.data);
                                that.$emit('reMajor', that.type)
                            }
                        })
                    }
                }
            })
        },

        // 上传图片
        uploadFrontImg(params) {
            var that = this;
            var file = params.file;
            const isLt5M = file.size / 1024 / 1024 < 10;
            if (isLt5M) {
                if (['image/png', 'image/jpeg', 'image/pbmp', 'image/jpg'].indexOf(file.type) !== -1) {
                    that.utils.upload(file,function(url){
	                    if (!url){
		                    return false;
	                    }
                        that.cartImg = url.url
                    })
                } else {
                    this.$message.error('只能上传图片类型!')
                }

            } else {
                this.$message.error('上传文件大小不能超过 10MB!')
            }
        }
    }
}
</script>

<style scoped>
.open-btn {
    width: 76px;
    height: 38px;
    padding: 2px 0;
    line-height: 6px;
    background-color: #E4E6EB;
    border-radius: 5px;
}

.save-btn {
    width: 68px;
    height: 38px;
    color: #fff;
    background-color: #1C7EFF;
    border-radius: 5px;
}

.cancel-btn {
    width: 68px;
    height: 38px;
    margin-right: 18px;
    background-color: #E4E6EB;
    border-radius: 5px;
}

.resume-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
}

.upload-demo {
    vertical-align: middle;
}

.el-icon-plus {
    color: #2970FF;
    font-size: 44px;
    font-weight: 600;
    text-shadow: 0 3px 6px rgba(0,0,0,0.16);
    margin: 100px 0 14px 0;
}

/deep/ .el-upload-dragger {
    width: 516px;
    height: 338px;
    background-color: #FBFBFB;
}

/deep/ .el-checkbox__inner {
    width: 18px;
    height: 18px;
    border: #1C7EFF 2px solid;
}

/deep/ .el-checkbox__inner::after {
    left: 6px;
    height: 9px;
    width: 4px;
}

/deep/ .el-upload-dragger {
    width: 500px;
    height: 300px;
    background-color: #FBFBFB;
}
</style>
