<template>
    <div class="other-data">
        <div class="content" v-if="otherData.type ==='sketch' && updateShow === false">
            <div style="display: flex; justify-content: space-between; margin-bottom: 20px">
                <div style="margin-top: 10px;width: 72%">
                    <span class="content-title">项目名称：</span>
                    <span>{{otherInfo.name}}</span>
                </div>
                <div style="display: flex">
                    <div style="margin-right: 20px">
                        <img src="../../../../assets/imgs/circle/lock.png" style="vertical-align: middle; margin-right: 4px"/>
                        <span style="vertical-align: middle; font-size: 14px" v-if="otherInfo.isOvert == '1'">所有人可见</span>
                        <span style="vertical-align: middle; font-size: 14px" v-if="otherInfo.isOvert == '0'">仅自己可见</span>
                    </div>
                    <div v-if="user.expertAuthStatus !== 1" class="hand op" style="margin-right: 20px" @click="updateShow = true">
                        <img src="../../../../assets/imgs/circle/editorC.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                    <div v-if="user.expertAuthStatus !== 1" class="hand op" @click="deleteInfo">
                        <img src="../../../../assets/imgs/circle/trash.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                </div>
            </div>
            <div class="info">
                <span class="content-title">工作内容：</span>
                <span>{{otherInfo.content}}</span>
            </div>
            <div class="info">
                <span class="content-title">项目周期：</span>
                <span v-if="otherInfo.time">{{otherInfo.time[0]}} 至 {{otherInfo.time[1]}}</span>
            </div>
        </div>
        <ExpertFrom v-if="otherData.type === 'sketch' && updateShow === true" @reMajor="getMajor" @upOtherData="updateChange" :type="otherData.type" :otherData="otherInfo"></ExpertFrom>

        <div class="content" v-if="otherData.type ==='monograph' && updateShow === false">
            <div style="display: flex; justify-content: space-between; margin-bottom: 20px">
                <div style="margin-top: 10px;width: 72%">
                    <span class="content-title">专著名称：</span>
                    <span>{{otherInfo.monographName}}</span>
                </div>
                <div style="display: flex">
                    <div style="margin-right: 20px">
                        <img src="../../../../assets/imgs/circle/lock.png" style="vertical-align: middle; margin-right: 4px"/>
                        <span style="vertical-align: middle; font-size: 14px" v-if="otherInfo.isOvert == '1'">所有人可见</span>
                        <span style="vertical-align: middle; font-size: 14px" v-if="otherInfo.isOvert == '0'">仅自己可见</span>
                    </div>
                    <div v-if="user.expertAuthStatus !== 1" class="hand op" style="margin-right: 20px" @click="updateShow = true">
                        <img src="../../../../assets/imgs/circle/editorC.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                    <div v-if="user.expertAuthStatus !== 1" class="hand op" @click="deleteInfo">
                        <img src="../../../../assets/imgs/circle/trash.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                </div>
            </div>
            <div class="info">
                <span class="content-title">出版社信息：</span>
                <span>{{otherInfo.press}}</span>
            </div>
            <div class="info">
                <span class="content-title">专著其他说明：</span>
                <span>{{otherInfo.explain}}</span>
            </div>
            <div class="info">
                <span class="content-title">出版日期：</span>
                <span>{{otherInfo.time}}</span>
            </div>
        </div>
        <ExpertFrom v-if="otherData.type === 'monograph' && updateShow === true" @reMajor="getMajor" @upOtherData="updateChange" :type="otherData.type" :otherData="otherInfo"></ExpertFrom>

        <div class="content" v-if="otherData.type ==='paper' && updateShow === false">
            <div style="display: flex; justify-content: space-between; margin-bottom: 20px">
                <div style="margin-top: 10px;width: 72%">
                    <span class="content-title">论文标题：</span>
                    <span>{{otherInfo.paperName}}</span>
                </div>
                <div style="display: flex">
                    <div style="margin-right: 20px">
                        <img src="../../../../assets/imgs/circle/lock.png" style="vertical-align: middle; margin-right: 4px"/>
                        <span style="vertical-align: middle; font-size: 14px" v-if="otherInfo.isOvert == '1'">所有人可见</span>
                        <span style="vertical-align: middle; font-size: 14px" v-if="otherInfo.isOvert == '0'">仅自己可见</span>
                    </div>
                    <div v-if="user.expertAuthStatus !== 1" class="hand op" style="margin-right: 20px" @click="updateShow = true">
                        <img src="../../../../assets/imgs/circle/editorC.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                    <div v-if="user.expertAuthStatus !== 1" class="hand op" @click="deleteInfo">
                        <img src="../../../../assets/imgs/circle/trash.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                </div>
            </div>
            <div class="info">
                <span class="content-title">发表刊物及刊号：</span>
                <span>{{otherInfo.paperNo}}</span>
            </div>
            <div class="info">
                <span class="content-title">发表日期：</span>
                <span>{{otherInfo.time}}</span>
            </div>
        </div>
        <ExpertFrom v-if="otherData.type === 'paper' && updateShow === true" @reMajor="getMajor" @upOtherData="updateChange" :type="otherData.type" :otherData="otherInfo"></ExpertFrom>

        <div class="content" v-if="otherData.type ==='rank' && updateShow === false">
            <div style="display: flex; justify-content: space-between; margin-bottom: 20px">
                <div style="margin-top: 10px;width: 72%">
                    <span class="content-title">社会头衔：</span>
                    <span>{{otherInfo.name}}</span>
                </div>
                <div style="display: flex">
                    <div style="margin-right: 20px">
                        <img src="../../../../assets/imgs/circle/lock.png" style="vertical-align: middle; margin-right: 4px"/>
                        <span style="vertical-align: middle; font-size: 14px" v-if="otherInfo.isOvert == '1'">所有人可见</span>
                        <span style="vertical-align: middle; font-size: 14px" v-if="otherInfo.isOvert == '0'">仅自己可见</span>
                    </div>
                    <div v-if="user.expertAuthStatus !== 1" class="hand op" style="margin-right: 20px" @click="updateShow = true">
                        <img src="../../../../assets/imgs/circle/editorC.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                    <div v-if="user.expertAuthStatus !== 1" class="hand op" @click="deleteInfo">
                        <img src="../../../../assets/imgs/circle/trash.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                </div>
            </div>
            <div>
                <div style="font-size: 18px; margin-bottom: 18px" class="content-title">头衔证书（不对外展示）</div>
                <img :src="otherInfo.cartImg" style="width: 528px; height: 338px; border-radius: 5px;margin-right: 20px;">
            </div>
        </div>
        <ExpertFrom v-if="otherData.type === 'rank' && updateShow === true" @reMajor="getMajor" @upOtherData="updateChange" :type="otherData.type" :otherData="otherInfo"></ExpertFrom>

        <div class="content" v-if="otherInfo.type === 'topic' && updateShow === false">
            <div style="display: flex; justify-content: space-between; margin-bottom: 20px">
                <div style="margin-top: 10px;width: 72%">
                    <span class="content-title">专业领域：</span>
                    <span>{{otherInfo.typeName[2]}}</span>
                </div>
                <div style="display: flex">
                    <div class="hand op" style="margin-right: 20px" @click="updateShow = true">
                        <img src="../../../../assets/imgs/circle/editorC.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                    <div class="hand op" @click="deleteInfo">
                        <img src="../../../../assets/imgs/circle/trash.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                </div>
            </div>
            <div class="info">
                <span class="content-title">专业领域话题：</span>
                <span>{{otherInfo.title}}</span>
            </div>
            <div class="info">
                <span class="content-title">价格：</span>
                <span>{{otherInfo.money}}</span>
            </div>
            <div class="info">
                <span class="content-title">话题描述：</span>
                <span>{{otherInfo.detail}}</span>
            </div>
            <div class="info">
                <span class="content-title">是否上架：</span>
                <span v-if="otherInfo.status == 2">上架</span>
                <span v-if="otherInfo.status == 1">下架</span>
            </div>
        </div>
        <ExpertFrom v-if="otherInfo.type === 'topic' && updateShow === true" @reMajor="getMajor" type="topic" :otherData="otherInfo"></ExpertFrom>

        <div class="content" v-if="otherData.type ==='other' && updateShow === false">
            <div style="display: flex; justify-content: space-between; margin-bottom: 20px">
                <div style="margin-top: 10px;width: 72%">
                    <span class="content-title">参与制定本专业法律法规、标准规范情况：</span>
                    <span>{{otherInfo.name}}</span>
                </div>
                <div style="display: flex">
                    <div style="margin-right: 20px">
                        <img src="../../../../assets/imgs/circle/lock.png" style="vertical-align: middle; margin-right: 4px"/>
                        <span style="vertical-align: middle; font-size: 14px" v-if="otherInfo.isOvert == '1'">所有人可见</span>
                        <span style="vertical-align: middle; font-size: 14px" v-if="otherInfo.isOvert == '0'">仅自己可见</span>
                    </div>
                    <div v-if="user.expertAuthStatus !== 1" class="hand op" style="margin-right: 20px" @click="updateShow = true">
                        <img src="../../../../assets/imgs/circle/editorC.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                    <div v-if="user.expertAuthStatus !== 1" class="hand op" @click="deleteInfo">
                        <img src="../../../../assets/imgs/circle/trash.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                </div>
            </div>
        </div>
        <ExpertFrom v-if="otherData.type === 'other' && updateShow === true" @reMajor="getMajor" @upOtherData="updateChange" :type="otherData.type" :otherData="otherInfo"></ExpertFrom>
    </div>
</template>

<script>

import ExpertFrom from "./ExpertFrom";
export default {
    name: "ExpertData",
    components: {ExpertFrom},
    data() {
        return {
            otherInfo: {},
            updateShow: false,
        }
    },
    props: {
        otherData: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created: function () {

    },
    mounted: function () {
        this.otherInfo = this.otherData;
        if (!this.otherData.type) {
            this.otherInfo.type = 'topic';
        }
    },
    methods: {
        // 删除
        deleteInfo() {
            if (this.otherInfo.type === 'topic') {
                this.newApi.delExpertTopic({id: this.otherInfo.id}).then(res => {
                    if (res.isSuccess === 1) {
                        this.utils.sus(res.data);
                        this.$emit('reMajor')
                    }
                })
            } else {
                this.$emit('delOtherData', this.otherData.type)
            }
        },

        // 更新
        updateChange(item) {
            this.updateShow = false;
            this.$emit('upOtherData', item)
        },

        getMajor() {
            this.updateShow = false;
            this.$emit('reMajor', this.otherInfo.type)
        },
    }
}
</script>

<style scoped>
.other-data {
  margin-bottom: 10px;
}

.content {
  font-size: 18px;
  padding: 20px;
  box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
  border-radius: 5px 5px 5px 5px;
  border: 1px solid #BDBFC1;
}

.info {
    margin-bottom: 20px;
}

.content-title {
    font-weight: 600;
}

/*公共*/
.hand{
    cursor:pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.op:active{
    opacity:0.6;
    -webkit-transition:all .1s;transition:all .1s;opacity:.6;
}

.op{
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all .5s;
    transition: all .5s;
}
</style>
